import(/* webpackMode: "eager" */ "/vercel/path0/apps/netthandel/src/cms/live-preview/LivePreview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Slideshow"] */ "/vercel/path0/apps/netthandel/src/components/Slideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccordionSection"] */ "/vercel/path0/apps/netthandel/src/sections/AccordionSection/AccordionSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/AccordionSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/AuthorFocusSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/BannerSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/ExperimentSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Feedback"] */ "/vercel/path0/apps/netthandel/src/sections/FeedbackSection/Feedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/FeedbackSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/HeroSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/ImageSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/LinkCardListSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/LinkPanelSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/MediaSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/ProductCarouselSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductCarousel"] */ "/vercel/path0/apps/netthandel/src/sections/ProductCarouselSection/ProductCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/ProductFocusSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/RichButtonSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RichButtonSection"] */ "/vercel/path0/apps/netthandel/src/sections/RichButtonSection/RichButtonSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/RichTextSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/SectionList/SectionListPreview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/SlideshowSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/StepCardsSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/SubscriptionCampaignFocusSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubscriptionCampaignFocus"] */ "/vercel/path0/apps/netthandel/src/sections/SubscriptionCampaignFocusSection/SubscriptionCampaignFocus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/ValuePropositionsSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/sections/VideoSection/Preview.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/netthandel/src/tracking/CustomTracking/ComponentViewTracker.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Carousel/useCarousel.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Modal/Modal.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Table/TableRows.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tabs/Tabs.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/design-system/dist/components/Tooltip/Tooltip.js");
